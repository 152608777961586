import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container, Card, Button, Row, Col } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'

import MainLayout from '../components/main-layout'
import HeaderBlock from '../components/header-block'
import Seo from '../components/seo'

import { animate, dontUnderline } from '../styles/common.module.css'
import { postImage, markdownImage } from '../pages/news/news.module.css'


const NewsPage = ({ data, pageContext }) => {

    return (
        <MainLayout pageTitle={"News - Page " + pageContext.currentPage.toString()}>

            <Seo title={"News - Page " + pageContext.currentPage.toString()} description="News and announcements from ai03 Design Studio" />

            <Container>
                <HeaderBlock header={"News - Page " + pageContext.currentPage.toString()} />
                {
                    data.allStrapiNewsPost.nodes.map(post => (
                        <Card key={post.id} className={`rounded shadow mb-5 ${animate}`}>
                            {
                                post.image &&
                                <Link to={"/news/article-" + post.slug}>
                                    <Card.Img as={GatsbyImage} image={post.image.localFile.childImageSharp.gatsbyImageData} alt="" variant="top" className={`rounded-top ${postImage}`} />
                                </Link>
                            }
                            <Card.Body>
                                <Link to={"/news/article-" + post.slug}>
                                    <Card.Title as="h1" className="text-decoration-none">{post.title}</Card.Title>
                                </Link>
                                <small>{post.created_at}</small>
                                <ReactMarkdown className={`pt-3 ${markdownImage}`} as={Card.Text} children={post.content} />
                            </Card.Body>
                            {
                                post.relatedboards.length > 0 &&
                                <Card.Footer>
                                    <p className="mb-0">Projects related to this article:</p>
                                    <Row xs={3} md={4} lg={6}>
                                        {
                                            post.relatedboards.map(board => (
                                                <Link key={board.id} as={Col} to={"/projects/" + board.slug} className={`p-0 p-1 ${dontUnderline}`}>
                                                    <Card className="rounded shadow-sm  p-0 h-100">
                                                        <Card.Img as={GatsbyImage} image={board.cover.localFile.childImageSharp.gatsbyImageData} alt="" variant="top" className={`rounded-top ${postImage}`} />
                                                        <Card.Body className="text-center">
                                                            <Card.Title as="p" className="text-decoration-none mb-0">{board.boardname}</Card.Title>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>

                                            ))
                                        }
                                    </Row>

                                </Card.Footer>
                            }
                        </Card>
                    ))
                }
                <Row xs={2} md={3} lg={4} xl={8} className="justify-content-center mt-3 mb-3">
                    {
                        pageContext.currentPage > 2 &&
                        <Col>
                            <Button className="w-100" as={Link} to={"/news/" + (pageContext.currentPage - 1).toString()}>Previous Page</Button>
                        </Col>
                    }
                    {
                        pageContext.currentPage === 2 &&
                        <Col>
                            <Button className="w-100" as={Link} to={"/news"}>Previous Page</Button>
                        </Col>
                    }
                    {
                        pageContext.currentPage < pageContext.numPages &&
                        <Col>
                            <Button className="w-100" as={Link} to={"/news/" + (pageContext.currentPage + 1).toString()}>Next Page</Button>
                        </Col>
                    }
                </Row>

            </Container>


        </MainLayout>
    )
}

export const query = graphql`
query($skip: Int, $limit: Int) {
    allStrapiNewsPost(
      sort: {fields: created_at, order: DESC}
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        title
        content
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.618
                transformOptions: { fit: COVER }
                placeholder: BLURRED
                quality: 98
                width: 750
              )
            }
          }
        }
        slug
        relatedboards {
          id
          boardname
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(
                    aspectRatio: 1.618
                    transformOptions: { fit: COVER }
                    placeholder: BLURRED
                    width: 300
                    quality: 90
                )
              }
            }
          }
          slug
        }
        created_at(formatString: "YYYY-MM-DD")
      }
    }
  }  
`

export default NewsPage